/**
 * Navigation component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import styled from "styled-components"

function Navigation() {
  return (
    <StaticQuery
      query={navigationQuery}
      render={data => {
        const { menuLinks } = data.site.siteMetadata
        return (
          <Container>
            <nav>
              <ul style={{ display: "flex", flex: 1 }}>
                {menuLinks.map(link => (
                  <li
                    key={link.name}
                    style={{
                      listStyleType: `none`,
                      padding: `1rem`,
                    }}
                  >
                    <Link to={link.link}>
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </Container>
        )
      }}
    />
  )
}

const navigationQuery = graphql`
  query NavigationQuery {
    site {
      siteMetadata {
        menuLinks{
          name
          link
        }
      }
    }
  }
`

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
`


export default Navigation
